import React, { useEffect, useRef, useState, useCallback } from 'react';
import ArrowIcon from '../../assets/ArrowIcon';
import GearIcon from '../../assets/GearIcon';
import CloseIcon from '../../assets/CloseIcon';
import './style.css';

const initialCellWidth = 28;
const initialGridSize = 9;
const maxGridSize = 50;
const minGridSize = 3;

function Snake() {
  const getInitialSnake = (_gridSize, _cellWidth) => {
    const initialSnakePosition = Math.floor(_gridSize / 2) * _cellWidth;
    return [{ x: initialSnakePosition, y: initialSnakePosition }];
  }

  const isEatingRef = useRef(false);
  const directionRef = useRef(null);
  const prevDirectionRef = useRef(null);
  const gridSizeRef = useRef(initialGridSize);
  const timeoutIdRef = useRef(null);
  const scoreRef = useRef(0);
  const highScoreRef = useRef(0);

  const [gridSize, setGridSize] = useState(initialGridSize);
  const [newGridSize, setNewGridSize] = useState();
  const [snake, setSnake] = useState(() => getInitialSnake(initialGridSize, initialCellWidth));
  const [food, setFood] = useState(null);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [isControlsVisible, setIsControlsVisible] = useState(false);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);

  const getUpdatedSnake = useCallback((newCell, prev) => {
    let isGameOver = false;

    const cellWidth = getCellWidth(gridSizeRef.current);

    if (
      newCell.x < 0 ||
      newCell.x > (cellWidth * (gridSizeRef.current - 1)) ||
      newCell.y < 0 ||
      newCell.y > (cellWidth * (gridSizeRef.current - 1))
    ) {
      isGameOver = true; // game over if snake hits wall
    }

    if (!isGameOver) {
      for (let i = 0; i < prev.length; i++) {
        if (newCell.x === prev[i].x && newCell.y === prev[i].y) {
          isGameOver = true; // game over if snake hits itself
        }
      }
    }

    if (isGameOver) {
      if (highScoreRef.current < scoreRef.current) {
        localStorage.setItem('highScore', scoreRef.current);
        setHighScore(scoreRef.current);
        alert(`Game Over! New High Score: ${scoreRef.current}`);
      } else {
        alert(`Game Over! Score: ${scoreRef.current}`);
      }
  
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
  
      directionRef.current = null;
      prevDirectionRef.current = null;
      setFood(null);
      timeoutIdRef.current = null;
      setScore(0);

      return getInitialSnake(gridSizeRef.current, getCellWidth(gridSizeRef.current));
    }

    return [newCell, ...prev.slice(0, -1)];
  }, []);

  const handleAnimation = useCallback(() => {
    const cellWidth = getCellWidth(gridSizeRef.current);
    const snakeSpeed = getSnakeSpeed(gridSizeRef.current);

    if (directionRef.current === 'up') {
      setSnake(prev => getUpdatedSnake({ x: prev[0].x, y: prev[0].y - cellWidth }, prev));
    } else if (directionRef.current === 'right') {
      setSnake(prev => getUpdatedSnake({ x: prev[0].x + cellWidth, y: prev[0].y }, prev));
    } else if (directionRef.current === 'down') {
      setSnake(prev => getUpdatedSnake({ x: prev[0].x, y: prev[0].y + cellWidth }, prev));
    } else if (directionRef.current === 'left') {
      setSnake(prev => getUpdatedSnake({ x: prev[0].x - cellWidth, y: prev[0].y }, prev));
    }

    prevDirectionRef.current = directionRef.current;

    timeoutIdRef.current = setTimeout(handleAnimation, snakeSpeed);
  }, [getUpdatedSnake]);

  const randomizeFood = useCallback(() => {
    const cellWidth = getCellWidth(gridSizeRef.current);

    const x = Math.floor(Math.random() * gridSizeRef.current) * cellWidth;
    const y = Math.floor(Math.random() * gridSizeRef.current) * cellWidth;

    setFood({ x, y });
  }, []);

  const handleMove = useCallback(direction => {
    if(!timeoutIdRef.current) {
      randomizeFood();
      handleAnimation();
    }
    switch(direction) {
      case 'ArrowUp':
        if (prevDirectionRef.current !== 'down') {
          directionRef.current = 'up';
        }
        break;
      case 'ArrowRight':
        if (prevDirectionRef.current !== 'left') {
          directionRef.current = 'right';
        }
        break;
      case 'ArrowDown':
        if (prevDirectionRef.current !== 'up') {
          directionRef.current = 'down';
        }
        break;
      case 'ArrowLeft':
        if (prevDirectionRef.current !== 'right') {
          directionRef.current = 'left';
        }
        break;
      default:
        break;
    }
  }, [handleAnimation, randomizeFood]);

  const handleKeyDown = useCallback(e => {
    if (e.key.startsWith('Arrow')) {
      handleMove(e.key);
    }
  }, [handleMove]);

  useEffect(() => {
    setIsControlsVisible(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));

    setHighScore(localStorage.getItem('highScore') || 0);

    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [handleKeyDown]);

  useEffect(() => {
    if (food && snake && snake[0] && food.x === snake[0].x && food.y === snake[0].y) {
      randomizeFood();
      setScore(prev => prev + 1);
      isEatingRef.current = true;
      setSnake(prev => [...prev, prev[prev.length - 1]]);
    }
  }, [food, snake, randomizeFood]);

  useEffect(() => {
    if (isSettingsVisible) {
      setNewGridSize(gridSize);
    } else {
      setNewGridSize();
    }
  }, [isSettingsVisible, gridSize]);

  useEffect(() => {scoreRef.current = score}, [score]);

  useEffect(() => {highScoreRef.current = highScore}, [highScore]);

  const handleApplyClicked = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    directionRef.current = null;
    prevDirectionRef.current = null;
    setFood(null);
    timeoutIdRef.current = null;
    setScore(0);

    setGridSize(newGridSize);
    gridSizeRef.current = newGridSize;
    setSnake(getInitialSnake(newGridSize, getCellWidth(newGridSize)));
    setIsSettingsVisible(false);
  }

  const getCellWidth = _gridSize => Math.floor(260 / _gridSize);

  const getSnakeSpeed = _gridSize => Math.floor(2000/(_gridSize + 3)+10);

  const cellWidth = getCellWidth(gridSize);

  return <div className='snake-game-container'>
    <div className='snake-game-header'>
        <div>High Score: {highScore}</div>
        <div>Score: {score}</div>
        {!directionRef.current && <button
          className='open-settings'
          onClick={() => setIsSettingsVisible(true)}
        >
          <GearIcon/>
        </button>}
    </div>
    {isSettingsVisible && <div className='snake-game-settings'>
      <button
        className='close-settings'
        onClick={() => setIsSettingsVisible(false)}
      >
        <CloseIcon/>
      </button>
      <div className='snake-game-settings-body'>
        <div>
          Grid Size:
        </div>
        <div style={{ width: '62px' }}>
          {`${newGridSize} x ${newGridSize}`}
        </div>
        <div className='increment-controls'>
          <button onClick={() => setNewGridSize(newGridSize + 1)} className='up main-btn' disabled={newGridSize === maxGridSize}>
            <ArrowIcon/>
          </button>
          <button onClick={() => setNewGridSize(newGridSize - 1)} className='down main-btn' disabled={newGridSize === minGridSize}>
            <ArrowIcon/>
          </button>
        </div>
      </div>
      <button onClick={handleApplyClicked} className='apply-settings main-btn'>
        Apply
      </button>
    </div>}
    <div
      className='game-board'
      style={{
        width: cellWidth * gridSize,
        height: cellWidth * gridSize,
        backgroundSize: `${cellWidth}px 1px, ${cellWidth}px ${cellWidth}px`
      }}
    >
        {snake.map((s, i) => <div
            key={i}
            className='player-unit'
            style={{
              top: s.y,
              left: s.x,
              height: cellWidth,
              width: cellWidth
            }}
        >
            <div/>
        </div>)}
        {food && <div
            id='food-unit'
            style={{
              top: food.y,
              left: food.x,
              height: cellWidth,
              width: cellWidth
            }}
        >
            <div/>
        </div>}
        </div>
        <div style={{ height: 30, margin: 4 }}>{!directionRef.current ? 'Use arrow keys to begin' : ''}</div>
        {isControlsVisible && <div className='snake-game-controls'>
            <button onClick={() => handleMove('ArrowUp')} className='up'>
              <ArrowIcon/>
            </button>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button onClick={() => handleMove('ArrowLeft')} className='left'>
              <ArrowIcon/>
            </button>
            <button onClick={() => handleMove('ArrowDown')} className='down'>
              <ArrowIcon/>
            </button>
            <button onClick={() => handleMove('ArrowRight')} className='right'>
              <ArrowIcon/>
            </button>
            </div>
        </div>}
        <button onClick={() => setIsControlsVisible(prev => !prev)} className='toggle-controls main-btn'>
            {isControlsVisible ? 'Hide Controls' : 'Show Controls'}
        </button>
    </div>
}

export default Snake;
